import React from "react";
import {  XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine, BarChart, Bar, LabelList} from 'recharts';

import Component, {ComplexModel, ResultFormatting} from "../../../models/Interfaces.tsx";
import {Errorcomponent} from "../../index.js"
import { ParseComplexQueryResult, transformComplexData2, getGradientByIndex, FormatResultValue, transformDataToGroupedData } from "../../../util/commonfunctions.tsx";


import "./barchartvertical.css";

export default function Barchartvertical(props:Component){
  const { title, queryResult, resultFormatting} = props;

  let seriesValues; 
  let data:any[];
  let columnHeader;
  try {
    const parsedData:any[] = JSON.parse(queryResult);
    const numColumns = Object.keys(parsedData[0]).length;
    columnHeader = Object.keys(parsedData[0])[numColumns - 2];
    data = transformDataToGroupedData(parsedData);
    switch (numColumns) {
      case 2:
        seriesValues = [Object.keys(data[0])[1]];
        break;
      case 3:
        seriesValues = [...new Set(parsedData.map(item => item[Object.keys(item)[0]]))];
        break;
      default:
        throw new Error('Invalid column count');
    }
  } 
  catch (error) 
  {
    return <Errorcomponent component={props} message="Error: Fant feil i dataene." />;
  }
  // const result = ParseComplexQueryResult(queryResult);
  // if (result instanceof Error) {
  //   return <Errorcomponent
  //   component={props}
  //   message="Error: kunne ikke parse resultatet"
  //   />
  // }
  
  // const comparerModels: ComplexModel[] = result;


  // const seriesValues = Array.from(new Set(comparerModels.map(model => model.Serie)));


  // const data = transformComplexData2(comparerModels);
  // const columnHeader = Object.keys(JSON.parse(queryResult)[0]).slice(1)[0];

  const CustomTooltip = ({ active, payload, label }) => {
    const compact = payload && payload.some(entry => entry.value > 100000);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-vertical">
          <p>{`${columnHeader}: ${label}`}</p>
          <ul>
            {payload.map((entry, index) => {
              const displayValue = FormatResultValue(entry.value, resultFormatting, compact);

              return (
                <li key={index} style={{ color: entry.color || entry.fill }}>
                  {`${entry.dataKey}: ${displayValue}`}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  return(
    <div className="base-container">
    <div className="title-container">
      {title}
    </div>
    <div className="vertical-barchart-container">
      <ResponsiveContainer height={500} width="100%">
        <BarChart 
          layout="vertical"
          data={data}
        >
          <defs>
            {data.map((entry, index) => (
              React.cloneElement(getGradientByIndex(index + 1, true), { key: `gradientVertical-${index}` })
            ))}
          </defs>
          <XAxis type="number"
                tickFormatter={(value) =>
                  new Intl.NumberFormat('nb-NB', {
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(value)
                }
          />
          <YAxis dataKey="Xvalue" type="category" width={90} />
          <Tooltip cursor={{fill: 'var(--text-secondary)'}} content={<CustomTooltip />}/>
            <Legend 
              className="vertical-barchart_legend"
              layout="horizontal"
              align="left"    
              verticalAlign="top"
              wrapperStyle={{
                margin: '0 0 20px 40px',
                width:"80%",
                paddingBottom: 20
              }}
            />
          <ReferenceLine y={0} stroke="#000" />
            {seriesValues.map((key, index) => {
              return(
              <Bar 
                  key={index}
                  dataKey={key}
                  fill={`url(#gradientVertical${index + 1})`}
                  name={key}
              >
                <LabelList
                  dataKey={key}
                  position="right"
                  angle={-25}
                  offset={4}
                  formatter={(value) =>
                    new Intl.NumberFormat("nb-NO", {
                      notation: 'compact',
                      compactDisplay: 'short',
                    }).format(value)
                  }
                />
              </Bar>)
            })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
  );
}