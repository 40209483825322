
import React from "react";

import "./../index.css";

export default function Toggleswitchcontrol({ handleChange, isChecked }){

  return (
    <div className={`toggle-switch-control ${isChecked ? 'dark' : 'light'}`} onClick={handleChange}>
      <div className="toggle-switch-slider">
      </div>
    </div>
  );
}