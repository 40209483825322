import React from 'react'
import { ResultFormatting, TotalFormatting } from '../../models/Interfaces.tsx';
import { FormatResultValue } from '../../util/commonfunctions.tsx';



interface TableBodyProps {
  tableData: any[]; // Adjust the type according to your data structure
  columns: any[]; // Adjust the type according to your data structure
  totalFormatting?: TotalFormatting;
}
export default function TableBody(props:TableBodyProps) { //{ tableData, columns, totalFormatting:TotalFormatting 
  const {tableData, columns, totalFormatting } = props;

  const lastColumnAccessor = columns[columns.length - 1].accessor;
  const isValueOverThreshold = tableData.some(data => parseFloat(data[lastColumnAccessor]) > 100000);

  const calculateTotalSum = () => {
    let sum = 0;
    tableData.forEach((data) => {
      const lastColumnValue = data[columns[columns.length - 1].accessor];
      sum += parseFloat(lastColumnValue);
    });
    return sum;
  };

  const calculateDifference = () => {
    const lastColumnAccessor = columns[columns.length - 1].accessor;
    const columnValues = tableData.map(data => parseFloat(data[lastColumnAccessor]));
    
    // Find the largest and smallest values in the column
    const max = Math.max(...columnValues);
    const min = Math.min(...columnValues);

    // Calculate the difference between the largest and smallest values
    const difference = Math.abs(max - min);

    return difference;
};

  const calculateAverage = () => {
    let total = 0;
    const lastColumnAccessor = columns[columns.length - 1].accessor;
    tableData.forEach((data) => {
      total += parseFloat(data[lastColumnAccessor]);
    });
    return total / tableData.length;
  };


  const renderBottomLine = () => {
    let calculatedValue = 0;
    let label = '';
  
    switch (totalFormatting) {
      case TotalFormatting.Sum:
        calculatedValue = calculateTotalSum();
        label = 'Total:';
        break;
      case TotalFormatting.Difference:
        calculatedValue = calculateDifference();
        label = 'Differanse:';
        break;
      case TotalFormatting.Average:
        calculatedValue = calculateAverage();
        label = 'Gjennomsnitt:';
        break;
      case TotalFormatting.SegmentedDifference:
        // You would set calculatedValue according to your logic for SegmentedDifference here
        label = 'Differanse segmentert:';
        break;
      default:
        return null; // In case totalFormatting doesn't match any case, nothing is rendered
    }
    // Common structure for rendering the calculated value according to the selected totalFormatting
    return (
    <tr>
      <td>{label}</td>
      {columns.length > 2 && columns.slice(1, -1).map((column, columnIndex) => (
        <td key={`blank-${columnIndex}`}></td>
      ))}
      <td key={`total-${columns[columns.length - 1].accessor}`}>
        {/* {FormatTotalValue(calculatedValue, totalFormatting, columns[columns.length - 1].formatValue, isValueOverThreshold)} */}
        {FormatResultValue(calculatedValue, columns[columns.length - 1].formatValue, isValueOverThreshold)}
      </td>
    </tr>
    );
  };

  return (
    <tbody>
      {tableData.map((data, index) => (
        <tr key={index}>
          {columns.map((column, columnIndex) => {
            const { accessor, formatValue } = column;
            const cellValue = data[accessor] !== undefined ? data[accessor] : "——";
            if (columnIndex === columns.length - 1) {
              // totalSum += parseFloat(cellValue);
              const formattedValue = FormatResultValue(cellValue, formatValue, isValueOverThreshold);//formatCellValue(cellValue, column, columnIndex, columns.length); 
              return <td key={accessor}>{formattedValue}</td>;
            }
            else
            {
              return <td key={accessor}>{cellValue}</td>;
            }
          })}
        </tr>
      ))}
      {renderBottomLine()}
      
    </tbody>
  );
};



      // <tr>
      //   <td>Total</td>
      //   {columns.slice(1).map((column, columnIndex) => {
      //     if (columnIndex === columns.length - 2) {
      //       Calculate and display the total sum for the last column
      //       return <td key={`total-${column.accessor}`}>{FormatResultValue(totalSum, column.formatValue)}</td>;
      //     } 
      //     else {
      //       return <td key={`total-${column.accessor}`} />;
      //     }
      //   })}
      // </tr>

//const formatCellValue = (value, column, columnIndex, totalColumns) => {

  //return FormatResultValue(value, column.formatValue);
  // if (columnIndex === totalColumns - 1) {
  //   if (column.formatValue && column.formatValue === ResultFormatting.Currency) {
  //     const formatter = new Intl.NumberFormat('nb-NO', {
  //       style: 'currency',
  //       currency: 'NOK',
  //     });
  //     return formatter.format(value);
  //   } else if (column.formatValue && column.formatValue === ResultFormatting.Standard) {
  //     // Format as standard (with separators)
  //     return Number(value).toLocaleString('nb-NO');
  //   }else if(column.formatValue && column.formatValue === ResultFormatting.Percentage){
  //     return `${value}%`;
  //   }
  // }
  // return value;
//};
// {tableData.map((data) => {
//   return (
//     <tr key={data.id}>
//       {columns.map(({ accessor }) => {
//         const tData = data[accessor] ? data[accessor] : "——";
//         return <td key={accessor}>{tData}</td>;
//       })}
//     </tr>
//   );
// })}

  // if (columnIndex === totalColumns - 1) {
  //   if (column.formatValue && column.formatValue === 'currency') {
  //     const formatter = new Intl.NumberFormat('nb-NO', {
  //       style: 'currency',
  //       currency: 'NOK',
  //     });
  //     return formatter.format(value);
  //   } else if (column.formatValue && column.formatValue === 'standard') {
  //     // Format as standard (with separators)
  //     return Number(value).toLocaleString('nb-NO');
  //   }
  // }
  // return value;