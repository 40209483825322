import React from "react";
import {  XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line, CartesianGrid} from 'recharts';

import Component, {ComplexModel } from "../../models/Interfaces.tsx";
import {Errorcomponent} from "../index.js"
import { ParseComplexQueryResult, transformComplexData2, FormatResultValue, transformDataToGroupedData } from "../../util/commonfunctions.tsx";
import { Colors } from "../../util/constants.tsx";

import "./linechartcomponent.css"
export default function Linechart(props:Component) {
  const { title, queryResult, resultFormatting} = props;

  let seriesValues; 
  let data:any[];
  let columnHeader;
  try {
    const parsedData:any[] = JSON.parse(queryResult);
    const numColumns = Object.keys(parsedData[0]).length;
    columnHeader = Object.keys(parsedData[0])[numColumns - 2];
    data = transformDataToGroupedData(parsedData);
    switch (numColumns) {
      case 2:
        seriesValues = [Object.keys(data[0])[1]];
        break;
      case 3:
        seriesValues = [...new Set(parsedData.map(item => item[Object.keys(item)[0]]))];
        break;
      default:
        throw new Error('Invalid column count');
    }
  } 
  catch (error) 
  {
    return <Errorcomponent component={props} message="Error: Fant feil i dataene." />;
  }
  // const result = ParseComplexQueryResult(queryResult);
  // if (result instanceof Error) {
  //   return <Errorcomponent
  //   component={props}
  //   message="Error: kunne ikke parse resultatet"
  //   />
  // }
  
  // const comparerModels: ComplexModel[] = result;


  // const seriesValues = Array.from(new Set(comparerModels.map(model => model.Serie)));


  // const data = transformComplexData2(comparerModels);
  // const columnHeader = Object.keys(JSON.parse(queryResult)[0]).slice(1)[0];
 
  const CustomTooltip = ({ active, payload, label }) => {
    const compact = payload && payload.some(entry => entry.value > 100000);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`${columnHeader}: ${label}`}</p>
          <ul>
            {payload.map((entry, index) => {
              const displayValue = FormatResultValue(entry.value, resultFormatting, compact);
              return (
                <li key={index} style={{ color: entry.fill }}>
                  {`${entry.dataKey}: ${displayValue}`}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  return(
    <div className="base-container">
         <div className="title-container">
            {title}
        </div>
        <div className="linechart-container">
          <ResponsiveContainer height={350} width="100%">
          <LineChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Xvalue" 
              />
            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat("nb-NO", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(value)
              }
            />
            <Tooltip cursor={{fill: 'var(--text-secondary)'}} content={<CustomTooltip />}/>
            <Legend 
            layout="horizontal"
            align="left"    
            verticalAlign="top"
            wrapperStyle={{
              margin: '0 0 20px 40px',
              width:"80%",
              paddingBottom: 20
            }}/>
            {seriesValues.map((key, index) => {
              return <Line
                  // {...lineSettings[key] ?? {}}
                  // onMouseEnter={mouseEnter}
                  // onMouseLeave={mouseLeave}
                  key={index}
                  dataKey={key}
                  stroke={Colors[index % Colors.length]}
                  name={key}
                  strokeWidth={2}
              />
            })}
            </LineChart>
            </ResponsiveContainer>
            </div>
    </div>
  )
}

{/* <LineChart
              width={400}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(value)
              }
            />
            <Tooltip />
            <Legend/>
            {seriesValues.map((key, index) => {
              //console.log(key, index)
              return <Line
                  // {...lineSettings[key] ?? {}}
                  // onMouseEnter={mouseEnter}
                  // onMouseLeave={mouseLeave}
                  key={index}
                  dataKey={key}
                  stroke= {Colors[index % Colors.length]}
                  name={key}
              />
            })}
            </LineChart> */}


  // const groupedData = groupByXvalue(comparerModels);
  // console.log(groupedData, "groupedData")

  // const seriesValues = Array.from(new Set(comparerModels.map(model => model.Serie)));

  // console.log(seriesValues, "series")


  // const transformedData = comparerModels.reduce((result, item) => {
  //   const { Xvalue, Serie, Yvalue } = item;
  
  //   if (!result[Xvalue]) {
  //     result[Xvalue] = {};
  //   }
  
  //   result[Xvalue][Serie] = Yvalue;
  
  //   return result;
  // }, {});

  // console.log(transformedData, "transformed data")