import React, { useState, useEffect } from 'react';
import "./cookieconsent.css";
const CookieConsentModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleConsent = (consentType) => {
    // Save user's preference (consentType) in localStorage
    localStorage.setItem('cookieConsent', consentType);
    setIsVisible(false);
    // onConsent(consentType);
  };

  if (!isVisible) {
    return null;
  }
  return (
    <div className="cookie-consent-modal">
      <p>Vi benytter kryptert lokal lagring av dine data for å forbedre brukeropplevelsen. Ved å trykke aksepter gir du oss tillatelse til å lagre brukerdataen på din maskin.</p>
      <div className="cookie-consent-button-container">
        <button onClick={() => handleConsent('accept')}>Aksepter</button>
        <button onClick={() => handleConsent('decline')}>Avslå</button>
      </div>
      {/* <button onClick={() => handleConsent('customize')}>Customize</button> */}
    </div>
  );
};

export default CookieConsentModal;