import React, { useEffect, useState } from "react";
import Component, { SimpleModel } from "../../../models/Interfaces.tsx";
import { ParseSimpleQueryResult, generateColumnsFromData, groupAndSumSimpleData, groupTwoColumnData, mergeConnectionResults, sortDataByLastColumn } from "../../../util/commonfunctions.tsx";
import { PresetIcons } from "../../../util/constants.tsx";
import { Errorcomponent, Table } from "../../index.js"

import "./simpleranking.css"
import { useStateContext } from "../../../context/ContextProvider.tsx";

export default function Simpleranking(props: Component) {
  const { title, queryResult, connectionResults, resultFormatting } = props
  const result = JSON.parse(queryResult);

  const [componentData, setComponentData] = useState<string[]>([]); //JSON.parse(queryResult)

  const stateContext = useStateContext();
  const [parsingError, setParsingError] = useState('');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(false);
    // const result = JSON.parse(queryResult);

    if (result instanceof Error) {
      setParsingError("Error: kunne ikke parse resultatet");
    } 
    else 
    {
      const mergedData:string[] = mergeConnectionResults(connectionResults, stateContext.filter);
      
      if(mergedData.length < 1)
        return;
      const data = groupTwoColumnData(mergedData);
      const sortedData = sortDataByLastColumn(data).slice(0,5); 
      setComponentData(sortedData);
    }
    setMounted(true);
  }, [stateContext.filter]);

  const columns = generateColumnsFromData(result, resultFormatting, false);
  const lastObject = result[result.length - 1];
  const keys = Object.keys(lastObject);
  const lastColumnKey = keys[keys.length - 1];

  //console.log(componentData, "comp data");
  return (
    <div className="base-container simple-ranking-container">
      <div className="title-container ranking_title-container">
        {title}
      </div>
      {parsingError ? (
        <Errorcomponent
          component={props}
          message="Error: kunne ikke parse resultatet"
        />
      ) : (
        <>
          {mounted && componentData.length > 0 ? (
            <Table
              caption={''}
              columns={columns}
              data={componentData}
              defaultSortField={lastColumnKey}
              defaultSortOrder={'desc'}
            />
          ) : (
            <p>Ingen data tilgjengelig</p>
          )}
        </>
      )}
    </div>
  );
}




  // const result = ParseSimpleQueryResult(queryResult);
  // if (result instanceof Error) {
  //   return <Errorcomponent
  //     component={props}
  //     message="Error: kunne ikke parse resultatet"
  //   />
  // }

  // const componentData = groupTwoColumnData(result, 'desc').slice(0, 5);¨

      // const mergedData: string[] = connectionResults
      // .filter(result => stateContext.filter.includes(result.licenseNo) && !result.isError)
      // .reduce((merged: string[], result) => {
      // const parsedData: string[] = JSON.parse(result.data);
      // if (Array.isArray(parsedData)) {
      //   merged.push(...parsedData);
        
      // } else {
      //   merged.push(parsedData);
      // }
      // return merged;
      // }, []);
      
      // data.sort((item1, item2) => {
      //   // Extract the values of the last column from each item
      //   const keys = Object.keys(item1);
      //   const lastColumnKey = keys[keys.length - 1];
      //   const value1 = parseFloat(item1[lastColumnKey]);
      //   const value2 = parseFloat(item2[lastColumnKey]);
      
      //   // Compare the values and return the comparison result
      //   return value2 - value1; // Sort in descending order
      // }).slice(0,5);

{/* <div className="simple_ranking-table">
        <div className="simple_table-row simple_table-header">
          <div className="simple_table-cell">Variant</div>
          <div className="simple_table-cell">Value</div>
        </div>
        {data.map((item, index) => (
          <div className="simple_table-row" key={index}>
            <div className="simple_table-cell">{item.Variant}</div>
            <div className="simple_table-cell">{item.Value}</div>
          </div>
        ))}
      </div> */}