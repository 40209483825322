import React from 'react';

import "./accordionitem.css";

interface AccordionItemProps {
  question: string;
  answer: string;
  isActive: boolean;
  onClick: () => void;
}

export default function AccordionItem(props:AccordionItemProps){
  const { question, answer, isActive, onClick } = props;

  return (
    <div className={`accordion-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <span className="question"> {question} </span>
      
      {isActive && <div className="answer">{answer}</div>}
    </div>
  );
};