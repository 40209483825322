import React, { useState, useEffect } from 'react';
import { authorizeUser } from '../../services/api.tsx';
import { LoginProps, OverlayProps } from '../../models/Types.tsx';

import "./loginpage.css";
import { useStateContext } from '../../context/ContextProvider.tsx';
import { CredentialsModel } from '../../models/Dtos.tsx';
import Welcomepage from '../welcomepage/Welcompage.tsx';
import { decryptWithSalt, encryptWithSalt } from '../../util/commonfunctions.tsx';
import CookieConsentModal from '../cookieconsent/CookieConsent.tsx';
const Loginpage = ({ onLogin }) => {
  const stateContext = useStateContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [showInfo, setShowInfo] = useState(false);

  const salt = "k5s5sghh42";
  const consent = localStorage.getItem('cookieConsent');
  useEffect(() => {
    // Load username, password, and rememberMe state from localStorage
    
    if(consent !== 'accept')
      return;
    const encryptedUserData = localStorage.getItem('userData');
    if (encryptedUserData) {
      const userData = JSON.parse(encryptedUserData);
      // const decryptedUserData = decryptWithSalt(encryptedUserData, salt);

      setUsername(decryptWithSalt(userData.username, salt));
      setPassword(decryptWithSalt(userData.password, salt));
      setRememberMe(userData.rememberMe);
    }
  }, []);

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const toggleInfo = () => {
    if (error) {
      setError('');
    }
    setShowInfo(!showInfo);
  };

  const handleLogin = async () => {
   
    try {
      setLoading(true); // Set loading state to true when authorization process starts
      const userCredentials = {
        username: username,
        password: password
      };
      const response = await authorizeUser(userCredentials);
      const user = await response.json();
      if (response.status == 201) {
        const newOverlayContext: OverlayProps = {
          onClose: () => { },
          children: <Welcomepage />
        };
        stateContext.setOverlayContext(newOverlayContext);
        stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
      }
      if (user !== null) {
        const loginProp: LoginProps = {
          status: true,
          username: username,
          password: password
        }
        if(consent === "accept"){
          if (rememberMe) {
            const userData = {
              username: encryptWithSalt(username, salt),
              password: encryptWithSalt(password, salt),
              rememberMe: rememberMe
            };
            localStorage.setItem('userData', JSON.stringify(userData));
          } 
          else {
            localStorage.removeItem('userData');
          }
        }
        onLogin(loginProp);
      }
      else {
        onLogin(false, '', '');
      }


    } catch (error) {
      setError(error.message);
      onLogin(false, '', '');
    } finally {
      setLoading(false); // Set loading state to false when authorization process ends
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <>
    <CookieConsentModal />
   
    <div className="login-container">

      <h2>PCK Dashboard</h2>

      <div className="login-info-container">
        <h3>Logg inn</h3>
        <button
              type="button"
              id="action-button-save"
              className="info-btn" 
              onClick={toggleInfo}
            >
              <span aria-label="info">ℹ️</span>
            </button>
        {/* <button type="button" className="info-btn" onClick={toggleInfo}><span aria-label="info">ℹ️</span></button> */}
      </div>
      {showInfo && !error && (
        <div className="info-text">
          {/* Information text */}
          Her benytter du samme innlogging som for min.pckasse.no. Du skaffer deg tilganger ved å gå til PCKasse installasjonene, åpne admin, og navigere deg gjennom Oppsett, Programinnstillinger, Rutiner. Her finner du: Tilgang min.pckasse.no. Trykk på Opprett, og gi din e-post addresse tilgang.
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      <div className="login_input-container">
        <label htmlFor="username">Brukernavn:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="login_input-container">
        <label htmlFor="password">Passord:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
      <div className="login_remember-me-container">
        <input
          type="checkbox"
          id="remember-me"
          checked={rememberMe}
          onChange={toggleRememberMe}
        />
        <span>Husk meg</span>
      </div>
      {/* Disable the button when loading */}
      <button className="submit-btn" onClick={handleLogin} disabled={loading}>
        <span className="button-text">{loading ? 'Laster...' : 'Login'}</span>
        {loading && <div className="loader"></div>}
      </button>
    </div>
    </>
  );
};

export default Loginpage;