import React, {useState, useEffect, useRef} from "react";
import { useStateContext } from "../../context/ContextProvider.tsx";
import ReactSpeedometer, {CustomSegmentLabelPosition, Transition} from "react-d3-speedometer";
import {ResponsiveContainer} from 'recharts'

import Component, {SimpleModel} from "../../models/Interfaces.tsx";
import {Errorcomponent} from "../"
import { ParseSimpleQueryResult, groupAndSumSimpleData } from "../../util/commonfunctions.tsx";

import "./speedometer.css"
export default function Speedometer(props:Component) {
  const { title, queryResult} = props
  const pos = CustomSegmentLabelPosition.Inside;
  const transformx = Transition.easeElastic;
  const stateContext = useStateContext();
  const textColor = stateContext.darkThemeEnabled ? '#FFFFFF' : '#555';
  const result = ParseSimpleQueryResult(queryResult);

  const [forceRender, setForceRender] = useState(false);
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    let resizeTimer;
    setMounted(false);
    const handleResize = () => {
      clearTimeout(resizeTimer);
      setForceRender(true);
  
      resizeTimer = setTimeout(() => {
        setForceRender(false);
      }, 500);
    };
    setForceRender(true);
    window.addEventListener('resize', handleResize);
    setMounted(true);
  
    return () => {
      clearTimeout(resizeTimer);
      window.removeEventListener('resize', handleResize);
  
      // Reset the mounted flag on component unmount
      // setMounted(false);
    };
    
  }, []);
  
  // Reset forceRender after the component is mounted
  useEffect(() => {
    if (mounted) {
      setForceRender(false);
    }
  }, [mounted]);

  if (result instanceof Error) {
    return <Errorcomponent
      component={props}
      message="Error: kunne ikke parse resultatet"
      />
  }

  // const comparerModels: SimpleModel[] = result;
  const data = groupAndSumSimpleData(result);
  if(data.length != 2){
    //console.error('Expected two items in parsedQueryResult');
    return <Errorcomponent component={props}  message="OBS! Ikke tilstrekkelig data"/>; 
  }
  var percentageDifference:number =
  (((data[1].Value - data[0].Value) / Math.abs(data[0].Value)) * 100); 

  const percentageDifferenceFormatted = percentageDifference.toFixed(0) +'%';
  let speedometerValue = (0 + 1000)/2 + percentageDifference * 5;

  if(speedometerValue < 0){
    speedometerValue = 0;
  }else if( speedometerValue > 1000){
    speedometerValue = 1000;
  }
  return(
    <>
      <div className="base-container">
        <div className="title-container speedometer-title-container">
          {title}
        </div>
        <ResponsiveContainer className="speedometer-wrapper" >
          <ReactSpeedometer
            forceRender={forceRender}
            fluidWidth={true}
            
            needleHeightRatio={0.7}
            value={speedometerValue}
            currentValueText={percentageDifferenceFormatted}
            labelFontSize="18"
            valueTextFontSize="22"
            customSegmentLabels={[
              { text: '-100%', position: pos, color: '#555' },
              { text: '-50%', position: pos, color: '#555' },
              { text: '0%', position: pos, color: '#555', fontSize: '19px' },
              { text: '50%', position: pos, color: '#555' },
              { text: '100%', position: pos, color: '#555' },
            ]}
            ringWidth={35}
            needleTransitionDuration={2000}
            needleTransition={transformx}
            needleColor={'#90f2ff'}
            textColor={textColor}
          />
          </ResponsiveContainer>
      </div>
    </>
  )
}