import React from "react";
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine, BarChart, Bar, LabelList } from 'recharts';

import Component, { ComplexModel, ResultFormatting } from "../../../models/Interfaces.tsx";
import { Errorcomponent } from "../../index.js"
import { ParseComplexQueryResult, transformComplexData2, getGradientByIndex, groupThreeColumnData, groupTwoColumnData, FormatResultValue, transformDataToGroupedData } from "../../../util/commonfunctions.tsx";

import "./barcharthorizontal.css";

export default function Barcharthorizontal(props: Component) {
  const { title, queryResult, resultFormatting } = props;
  

    let seriesValues; 
    let data:any[];
    let columnHeader;
    try {
      const parsedData:any[] = JSON.parse(queryResult);
      const numColumns = Object.keys(parsedData[0]).length;
      columnHeader = Object.keys(parsedData[0])[numColumns - 2];
      data = transformDataToGroupedData(parsedData);
      switch (numColumns) {
        case 2:
          seriesValues = [Object.keys(data[0])[1]];
          break;
        case 3:
          seriesValues = [...new Set(parsedData.map(item => item[Object.keys(item)[0]]))];
          break;
        default:
          throw new Error('Invalid column count');
      }
    } 
    catch (error) 
    {
      return <Errorcomponent component={props} message="Error: Fant feil i dataene." />;
    }


  const CustomTooltip = ({ active, payload, label }) => {
    const compact = payload && payload.some(entry => entry.value > 100000);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`${columnHeader}: ${label}`}</p>
          <ul>
            {payload.map((entry, index) => {
               const displayValue = FormatResultValue(entry.value, resultFormatting, compact);

              return (
                <li key={index} style={{ color: entry.color || entry.fill }}>
                  {`${entry.dataKey}: ${displayValue}`}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="base-container">
      <div className="title-container">
        {title}
      </div>
      <div className="horizontal_barchart-container">
        <ResponsiveContainer height={350} width="100%">
          <BarChart
            layout="horizontal"
            data={data}
            margin={{
              top: 40,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <defs>
              {data.map((entry, index) => (
                React.cloneElement(getGradientByIndex(index + 1, false), { key: `gradientHorizontal-${index}` })
              ))}
            </defs>
            <XAxis dataKey="Xvalue"/>
            <YAxis type="number"
              tickFormatter={(value) =>
                new Intl.NumberFormat('nb-NO', {
                  notation: 'compact',
                  compactDisplay: 'short',
                }).format(value)
              }

            />
            <Tooltip cursor={{ fill: 'var(--text-secondary)' }} content={<CustomTooltip />} />
            <Legend 
            layout="horizontal"
            align="left"    
            verticalAlign="top"
            wrapperStyle={{
              margin: '0 0 20px 20px',
              width:"80%",
              paddingBottom: 20
            }}/>
            <ReferenceLine y={0} stroke="#000" />
            {seriesValues.map((key, index) => {
              return (
                <Bar
                  key={index}
                  dataKey={key}
                
                  //fill= {Colors[index % Colors.length]}
                  fill={`url(#gradientHorizontal${index + 1})`}
                  name={key}
                >
                  <LabelList
                    dataKey={key}
                    position="top"
                    angle={-45}
                    offset={22}
                    formatter={(value) =>
                      new Intl.NumberFormat('nb-NO', {
                        notation: 'compact',
                        compactDisplay: 'short',
                        maximumSignificantDigits: 3
                      }).format(value)
                    }
                  />
                </Bar>)
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}


    // const seriesKeys = [...new Set(data.map(item => item.Series))];


  // const test = JSON.parse(queryResult);
  // // const res = groupThreeColumnData(test);
  // // const res2 = groupTwoColumnData(test);

  // debugger;
  //  const result = ParseComplexQueryResult(queryResult);
  // if (result instanceof Error) {
  //   return <Errorcomponent
  //     component={props}
  //     message="Error: kunne ikke parse resultatet"
  //   />
  // }

  // // const comparerModels: ComplexModel[] = result;

  // const seriesValues = Array.from(new Set(comparerModels.map(model => model.Serie)));
 
  // const data = transformComplexData2(comparerModels);
    // // const columnHeader = Object.keys(JSON.parse(queryResult)[0]).slice(1)[0];
  // const columnHeader = Object.keys(data[0]).slice(1)[0];