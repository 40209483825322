import React from "react";
import "./toggleswitchdarkmode.css";
import "./../index.css";
import { FaSun, FaMoon } from 'react-icons/fa';

export default function ToggleswitchDarkmode({ handleChange, isChecked }){

  return (
    <div className={`toggle-switch-control ${isChecked ? 'dark' : 'light'}`} onClick={handleChange}>
      <div className="toggle-switch-slider">
        {!isChecked && <FaSun className="sun-icon" />}
        {isChecked && <FaMoon className="moon-icon" />}
      </div>
    </div>
  );
}