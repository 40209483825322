
import React, {useState, useEffect } from 'react';
import { useStateContext } from './context/ContextProvider.tsx';
import { Overlay } from './containers/index.js'

import { Loginpage, Dashboardpage } from './pages/index.js';
import {LoginProps} from './models/Types.tsx';
import './App.css';
import { useToken } from './util/sessionFunctions.tsx';
import { CredentialsModel } from './models/Dtos.tsx';
import { Loading } from './components/index.js';


    // const userToken = JSON.parse(tokenString);
    // const { expirationTime, username, password } = userToken;

    // // Check if the token is expired
    // const currentTime = new Date().getTime();
    // if (currentTime > expirationTime) {
    //   // Token is expired, remove it from sessionStorage
    //   sessionStorage.removeItem('token');
    //   return null;
    // }

    // // Token is valid, decrypt password and return credentials
    // const decryptedPassword = decryptWithSalt(password, salt);

    // return { username, password: decryptedPassword };


export default function App() {
  const stateContext = useStateContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { token, setToken, decryptWithSalt} = useToken();
  const handleLogin = (props:LoginProps) => {
    const {status, username, password} = props;
    setIsLoggedIn(status);
    if (status) {
      setToken(username, password);
      const ucred:CredentialsModel= {
        username:username,
        password:password
      }
      stateContext.setCredentials(ucred);
    }
  };

  useEffect(() => {
    // debugger;
    if (token) {
      const salt = 'k3fx5wa';
      // debugger;
      const { username, password, expirationTime } = token;
      const currentTime = new Date().getTime();
      if (currentTime < expirationTime) {

        const decryptedPassword = decryptWithSalt(password, salt);
        stateContext.setCredentials({ username, password: decryptedPassword });
        setIsLoggedIn(true);
      }
      else 
      {
        stateContext.setCredentials(null);
        setIsLoggedIn(false);
        sessionStorage.removeItem('token');
        // setToken(null);
      }
      
      // stateContext.setCredentials({ username, password });
      // setIsLoggedIn(true);
    } 
    else 
    {
      stateContext.setCredentials(null);
      setIsLoggedIn(false);
    }
  }, []); //token

  return (
    <>
    {stateContext.credentials === null ? <Loginpage onLogin={handleLogin} /> :
    <div className={`App ${stateContext.showOverlay && stateContext.overlayContext != null ? 'no-scroll' : ''}`.trim()} data-theme={stateContext.darkThemeEnabled ? "dark" : "light"} >
      {stateContext.showOverlay && stateContext.overlayContext &&
        <Overlay {...stateContext.overlayContext} />
      }
      <div className="app-wrapper">
        <Dashboardpage /> 
      </div>
    </div>
    }
     </>
    
  );
}


// useEffect(() => {
//   const sessionToken = localStorage.getItem('sessionToken');
//   const expirationTime = parseInt(localStorage.getItem('expirationTime')); // Parse as integer
//   const currentTime = new Date().getTime();
//   if (sessionToken && expirationTime && currentTime < expirationTime) {
//     setIsLoggedIn(true);
//   }
// }, []);

        {/* <Header />
        <Actioncontainer />
        <Dashboard />
        <Footer /> */}
//{`App ${stateContext.darkThemeEnabled ? 'light-mode' : 'dark-mode' }`}


// const columns = [
//   { label: "Full Name", accessor: "full_name", sortable: true },
//   { label: "Email", accessor: "email", sortable: false },
//   { label: "Gender", accessor: "gender", sortable: true, sortbyOrder: "desc" },
//   { label: "Age", accessor: "age", sortable: true },
//   { label: "Start date", accessor: "start_date", sortable: true },
// ];

{/* <div className="table_container">
      <h1>Reusable sortable table with React</h1>
      <Table
        caption="Developers currently enrolled in this course. The table below is ordered (descending) by the Gender column."
        data={[
          {
            "id": 1,
            "full_name": "Wendall Gripton",
            "email": "wg@creative.org",
            "gender": "Male",
            "age": 100,
            "start_date": "2022-01-26"
          },
          {
            "id": 2,
            "full_name": "samuel Mollitt",
            "email": "smol@github.io",
            "gender": "Male",
            "age": 60,
            "start_date": null
          },
          {
            "id": 3,
            "full_name": "Ole Rogge",
            "email": "orog@usatoday.com",
            "gender": "Male",
            "age": 50,
            "start_date": "2021-06-23"
          },
          {
            "id": 4,
            "full_name": "Elwin Huyge",
            "email": null,
            "gender": "Male",
            "age": 34,
            "start_date": "2021-06-07"
          },
          {
            "id": 5,
            "full_name": "Anthe Maybery",
            "email": "amay@dyndns.org",
            "gender": "Female",
            "age": 31,
            "start_date": "2021-07-05"
          },
          {
            "id": 6,
            "full_name": "Arny Amerighi",
            "email": "aameri@ibm.com",
            "gender": "Male",
            "age": 35,
            "start_date": "2021-03-09"
          },
          {
            "id": 7,
            "full_name": "Lynnell Shimmans",
            "email": "lshimmans6@addthis.com",
            "gender": "Male",
            "age": null,
            "start_date": "2021-06-03"
          },
          {
            "id": 8,
            "full_name": "Pierre Klug",
            "email": "pklug7@virginia.edu",
            "gender": "Female",
            "age": 36,
            "start_date": "2021-09-19"
          },
          {
            "id": 9,
            "full_name": "Melantha Jakeway",
            "email": "mjakeway8@noaa.gov",
            "gender": "Female",
            "age": 48,
            "start_date": "2021-01-07"
          },
          {
            "id": 10,
            "full_name": "Jodi Nickless",
            "email": "jnickless9@dagon.com",
            "gender": "Male",
            "age": 36,
            "start_date": "2021-02-05"
          }
        ]}
        columns={columns}
      />
      <br />
      <br />
    </div> */}