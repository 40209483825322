import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider.tsx'
import { Errorcomponent, Loading, Messagebox, ToggleswitchControl } from '../../index.js';
// import { FaTimesCircle } from 'react-icons/fa'; //TODO add into presets.
import { ConnectionResults } from '../../../models/Interfaces.tsx';
import {Detailsprops} from '../../../models/Types.tsx'
import "./connectiondetailscomponent.css";
import { PresetIcons } from '../../../util/constants.tsx';

export default function ConnectionDetailscomponent(props:Detailsprops) {
  const {title, details} = props;
  // const stateContext = useStateContext();
  // const [connections, setConnections] = useState<ConnectionResults[]>([]);

  const getIcon = (con:ConnectionResults) => {
    if (con.isError)
      return <div className="details_table-icon-cell error">{PresetIcons.FaTimesCircle}</div>;
    else
      return <div className="details_table-icon-cell success">{PresetIcons.FaCheckCircle}</div>;
  }
  const formatResponseTime = (time: string) => {
    const [numbers] = time.split(" ");
    const formattedNumbers = numbers.replace(",", ".");
    const formattedTime = parseFloat(formattedNumbers).toFixed(2);
    return `${formattedTime} ms`;
  }
  return (
    <>
      <div className="base-container">
        
          <div className="title-container">{title}</div>
          <div className="details-table">
            <div className="details_table-row details_table-header">
            <div className="details_table-cell">Status</div>
              <div className="details_table-cell">Lisens</div>
              <div className="details_table-cell">Navn</div>
              <div className="details_table-cell">Respons-tid</div>
            </div>
            {details.map((detail, index) =>  (
              <div className="details_table-row" key={index}>
                <div className="details_table-cell">
                  {getIcon(detail)}
                </div>
                <div className="details_table-cell">{detail.licenseNo}</div>
                <div className="details_table-cell">{detail.name}</div>
                <div className="details_table-cell">{formatResponseTime(detail.responseTime)}</div>
              </div>
            ))}
          </div>
      </div>
    </>
  );
}