import { CredentialsModel } from "../models/Dtos";
import { User, UserComponent } from "../models/Interfaces";

// const exampleData = require("../example_data.json");
//const myData = async () => exampleData

//const API_URL = 'http://192.168.1.57:7221/api';

// const API_URL = 'http://192.168.1.57:5143/api';
const API_URL = 'https://pckdashboardapi.azurewebsites.net/api';

AbortSignal.timeout ??= function timeout(ms) {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), ms);
  return controller.signal;
};

export const getData = async (credentials: CredentialsModel,) => {
  const url = `${API_URL}/Payloads/GetPayload`
  const headers = new Headers({
    'Content-Type': 'application/json',
    'credential': JSON.stringify(credentials)
  });
  console.log(headers, "headers");
  try {
    // const response = await fetch(url, { method: 'Post', headers })
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      signal: AbortSignal.timeout(45000),
    });
    console.log(response, "response");
    if (response.ok) {
      if (response.status === 204) {
        console.log(response, "204 response test");
        return "";
      }
      console.log(response, "get initial payload response");
      return await response.json();
    }

    if (response.status === 401) {
      return "";
    }
    throw await response.json();
  }
  catch (error) {
    console.log("error for response");
    if (error.name === "AbortError") {
      throw new Error("Requesten tok lengre enn 30 sekunder. Prøv igjen.");
    }
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
};

export const getAllComponents = async () => {
  const url = `${API_URL}/Components/GetAll`
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  try {
    const response = await fetch(url, { method: 'Get', headers });
    if (response.ok) { //TODO check if response could be error message.
      console.log(response);
      return await response.json();
    }
    throw await response.json();
  }
  catch (error) {
    console.log(error);
    throw error;
  }
};
export const updateUserComponent = async (component: UserComponent) => {
  console.log(component, "updateUserComponent");
  const url = `${API_URL}/UserComponents/Update`
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  try {
    const response = await fetch(url, { method: 'Put', headers, body: JSON.stringify(component) });
    if (response.ok) {
      if (response.status === 204) {
        console.log(response, "204 response test");
        return "";
      }
      console.log(response, "updateUserComponents response");
      return await response.json();
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}
export const updateUserComponents = async (components: UserComponent[], username: string) => {
  console.log(components, "updateUserComponents");
  const url = `${API_URL}/UserComponents/UpdateAll`
  const headers = new Headers({
    'Content-Type': 'application/json',
    'username': username,
  });
  try {
    const response = await fetch(url, { method: 'Post', headers, body: JSON.stringify(components) });
    if (response.ok) {
      if (response.status === 204) {
        console.log(response, "204 response test");
        return "";
      }
      console.log(response, "updateUserComponents response");
      return await response.json();
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}

export const updateUserCompPrio = async (components: UserComponent[]) => {
  console.log(components, "updateUserComponents positions");
  const url = `${API_URL}/UserComponents/UpdatePrio`
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  try {
    console.log("updating positions");
    const response = await fetch(url, { method: 'Post', headers, body: JSON.stringify(components) });
    if (response.ok) {
      if (response.status === 204) {
        console.log(response, "204 response test");
        return "";
      }
      console.log(response, "updateUserComponents response");
      return "";
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}
export const removeUserComponent = async (userComponentId: number) => {
  console.log(userComponentId, "Remove User Component");
  const url = `${API_URL}/UserComponents/Delete/${userComponentId}`; // Include userComponentId in the URL
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  try {
    const response = await fetch(url, { method: 'Delete', headers }); // Use DELETE method
    if (response.ok) {
      console.log(response, "removeUserComponent response");
      return await response.json();
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}

export const getAllConnections = async (credentials: CredentialsModel) => {
  console.log("Getting all connections");
  const url = `${API_URL}/Payloads/GetConnections`
  const headers = new Headers({
    'Content-Type': 'application/json',
    'credential': JSON.stringify(credentials)
  });

  try {
    const response = await fetch(url, { method: 'POST', headers })

    if (response.ok) {
      if (response.status === 204) {
        console.log(response, "204 response test");
        return "";
      }
      console.log(response, "Get user connections");
      return await response.json();
    }

    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
};


export const updateUserSettings = async (user: User) => {
  console.log(user, "Updating user settings");
  const url = `${API_URL}/User/UpdateSettings`;
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  try {
    const response = await fetch(url, { method: 'Put', headers, body: JSON.stringify(user) });
    if (response.ok) {
      console.log(response, "update Usersettings response");
      return;
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}

export const getFAQ = async () => {
  console.log("Get FAQ");
  const url = `${API_URL}/FAQ/GetFaq`;
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  try {
    const response = await fetch(url, { method: 'Get', headers });
    if (response.ok) {
      if (response.status === 204) {
        console.log(response, "204 response test");
        return null;
      }
      console.log(response, "FAQ Response");
      return await response.json();
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}

export const createUser = async (credentials: CredentialsModel) => {
  const url = `${API_URL}/User/CreateUser`
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  try {
    const response = await fetch(url, { method: 'Post', headers, body: JSON.stringify(credentials) });

    if (response.ok) {
      return response;
    }

    // Log the response body
    const responseBody = await response.text();
    console.log('Response body:', responseBody);

    // Handle different HTTP status codes
    if (response.status === 409) {
      throw new Error("This user already exists");
    }
    throw new Error('Fikk ikke kontakt med APIet');
  }
  catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}

export const authorizeUser = async (credentials: CredentialsModel) => {
  const url = `${API_URL}/User/AuthorizeUser`
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  try {
    const response = await fetch(url, { method: 'Post', headers, body: JSON.stringify(credentials) });

    // debugger;
    if (response.ok) {

      return response;
    }
    const responseMessage = await response.json();
    if (response.status == 404) {
      // const responseBody = await response.text();
      console.log('Authorize Response body:', responseMessage);
      throw new Error(responseMessage);
    }
    if (responseMessage === 'Failed to fetch') {
      throw new Error("Ingen kontakt med APIet");
    }
    else {
      throw new Error(responseMessage);
    }
  }
  catch (error) {
    if (error instanceof Error) {
      if (error.message === 'Failed to fetch') {
        throw new Error("Fikk ikke kontakt med APIet")
      }
      throw new Error(error.message);
    }
    else {
      throw error;
    }
  }
}
