import React, {useState} from 'react'

import "./closebutton.css";


type ClosebuttonProps = {
  onClose: () => void;
}
export default function Closebutton(props:ClosebuttonProps) {
  const { onClose} = props;
  // const [isVisible2, setIsVisible] = useState(isVisible);

  const handleClick = () => {
    
    // setIsVisible(!isVisible);
    onClose();
  }
  return (
      <button 
        type="button" 
        className="close-button"
        aria-label="Close" 
        onClick={handleClick} 
        >
          <span aria-hidden="true">&times;</span>
        </button>
  )
}