import React from 'react'

import "./footer.css";

export default function Footer() {
  return(
    <div className="footer-container">
      <div className="copyright-container">
        <p>© PCK AS - All Rights Reserved </p>
      </div>
    </div>
  )
}