import React, { useState, useEffect } from 'react'
import { ComponentProp, OverlayProps } from '../../models/Types';
import { useStateContext } from '../../context/ContextProvider.tsx';
import { Errorcomponent, Smallcomponent, Closebutton, Simpleranking, Areachart, Piechartcomponent, Speedometer, Barcharthorizontal, Barchartvertical, Linechartcomponent, Customtooltip, ConnectionDetailscomponent, Table } from '../index.js';

import './parentcomponent.css';
import Complexranking from '../rankingcomponent/complexranking/Complexranking.tsx';
import { ComponentType, ConnectionResults, ResultFormatting, TableColumn } from '../../models/Interfaces.tsx';
import { generateColumnsFromData, groupThreeColumnData, groupTwoColumnData } from '../../util/commonfunctions.tsx';

export default function Parentcomponent(props: ComponentProp) {
  const { data, closeCallback } = props;
  const stateContext = useStateContext();
  const { connectionResults, totalFormatting } = data;
  // useEffect(() => {
  //   const mergedData: string[] = connectionResults
  //   .filter(result => stateContext.filter.includes(result.licenseNo) && !result.isError)
  //   .reduce((merged: string[], result) => {
  //   const parsedData: string[] = JSON.parse(result.data);
  //   if (Array.isArray(parsedData)) {
  //     merged.push(...parsedData);
      
  //   } else {
  //     merged.push(parsedData);
  //   }
  //   return merged;
  // }, []);
  //   const newData = {
  //     ...data,
  //     queryResult: mergedData
  //   };
  //   // console.log(newData, "newData");
  // }, [stateContext.filter]);
      // const filtered = connectionResults.filter(result => {
    //   const licenseNo = result.licenseNo;
    //   return stateContext.filter.includes(licenseNo);
    // });
    // console.log(filtered, "filtered");
    // debugger;
    // setFilteredResults(filtered);
  const createComponent = () => {

    switch (data.componentType) {
      case 0:
        return <Smallcomponent {...data} />;
      case 1:
        return <Areachart {...data} />;
      case 2:
        return <Piechartcomponent {...data} />;
      case 3:
        return <Speedometer {...data} />;
      case 4:
        return <Simpleranking {...data} />;
      case 5:
        return <Linechartcomponent {...data} />;
      case 6:
        return <Barcharthorizontal {...data} />;
      case 7:
        return <Barchartvertical {...data} />;
      case 8:
        return <Complexranking {...data} />;
      default:
        return <Errorcomponent component={data} message="Component-type does not exist." />;
    }
  };


  const myConnections = () => {
    // Assuming data is your object containing connectionResults
    const filterContext = stateContext.filter;
    const myFilter = filterContext.length ? filterContext : stateContext.connections.map(connection => connection.licenseNo);
    const successfulConnections2 = connectionResults.filter(result => !result.isError && myFilter.includes(result.licenseNo))

    if (successfulConnections2.length === myFilter.length) {
      return '';
    }
    else {
      return `${successfulConnections2.length}/${myFilter.length}`;
    }
    // const totalConnections = connectionResults.length;

    // const successfulConnections = connectionResults.filter(result => !result.isError).length;
    // if (successfulConnections === totalConnections) {
    //   return '';
    // }
    // else {
    //   return `${successfulConnections}/${totalConnections}`;
    // }

  };

  const viewConnectionDetailsHandler = () => {
    if (!stateContext.showOverlay) {
      const filterContext = stateContext.filter;
      const myFilter = filterContext.length ? filterContext : stateContext.connections.map(connection => connection.licenseNo);
      const filteredResult = connectionResults.filter(result => myFilter.includes(result.licenseNo));

      const newOverlayContext: OverlayProps = {
        onClose: () => { },
        children: <ConnectionDetailscomponent title="Tilkoblingsdetaljer" details={filteredResult} /> //TODO remove/add filter 
      };
      stateContext.setOverlayContext(newOverlayContext);
    }
    else {
      stateContext.setOverlayContext(null);
    }
    stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
  }




  const viewComponentDetails = () => {
    if (!stateContext.showOverlay && !stateContext.editMode) {
      const componentData = JSON.parse(data.queryResult);
      if (componentData.length === 0) {
        const errorOverlayContext: OverlayProps = {
          onClose: () => { },
          children: <p>Ingen data tilgjengelig</p> //TODO fix this no data available. 
        };
        stateContext.setOverlayContext(errorOverlayContext);
        stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
        return;
      }
      const numColumns = Object.keys(componentData[0]).length;
      let groupedData;
      if(numColumns == 2){
        groupedData = groupTwoColumnData(componentData);

      }
      if(numColumns == 3){
        groupedData = groupThreeColumnData(componentData);

      }
        // console.log('queryRes', data.queryResult);
        // console.log('Grouped data:', groupedData);
        const columns = generateColumnsFromData(groupedData, data.resultFormatting, true);
        const lastObject = groupedData[groupedData.length - 1];
        const keys = Object.keys(lastObject);
        const lastColumnKey = keys[keys.length - 1];
        // console.log(lastColumnKey, "lastColumnkey");
        const newOverlayContext: OverlayProps = {
          onClose: () => { },
          children:  <Table caption={data.title} columns={columns} data={groupedData} defaultSortOrder={'desc'} totalFormatting={totalFormatting}/> //defaultSortField={lastColumnKey}
        };
        stateContext.setOverlayContext(newOverlayContext);
    }
    else {
      stateContext.setOverlayContext(null);
    }
    stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
  }

  return (
    <>
      <div className="parent-container" >
        <div className="draggable-container"  onClick={viewComponentDetails}> {/*  */}
          {data.queryResult.length === 0 || data.queryResult === "[]"
            ? <Errorcomponent component={data} message="OBS! Ikke tilstrekkelig data funnet." />
            : createComponent()
          }
        </div>
        <div className="parent_container-relative" style={{ color: stateContext.editMode ? 'var(--text-primary)' : 'var(--error-color)' }} >
          {stateContext.editMode ? (
            <Closebutton onClose={() => closeCallback(data.userComponentId)} />
          ) : (
            <div>
              <button
                onClick={viewConnectionDetailsHandler}
                disabled={!myConnections()}
              >
                {myConnections()}
              </button>

            </div>
          )}
        </div>

      </div>
    </>


  );
}


        // groupedData = componentData.reduce((result, item) => {
        //   const [key, value] = Object.entries(item)[0];
        //   const [key2, value2] = Object.entries(item)[1];

        //   const formattedValue2 = Number(value2).toFixed(2);

        //   const existingItem = result.find(obj => obj[key] === value);
        //   if (existingItem) {
        //     existingItem[key2] = ((Number(existingItem[key2]) || 0) + Number(formattedValue2)).toFixed(2);
        //   } else {
        //     result.push({ [key]: value, [key2]: formattedValue2 });
        //   }
          
        //   return result;
        // }, []);

      //   groupedData = componentData.reduce((result, item) => {
      //     const [key, value] = Object.entries(item)[0];
      //     const [key2, value2] = Object.entries(item)[1];
      //     const [key3, value3] = Object.entries(item)[2];
  
      //     const formattedValue3 = Number(value3).toFixed(2);
      //     const existingItemIndex = result.findIndex(obj => obj[key] === value && obj[key2] === value2);
      //     if (existingItemIndex !== -1) {
      //         result[existingItemIndex][key3] = ((Number(result[existingItemIndex][key3]) || 0) + Number(formattedValue3)).toFixed(2);
      //     } else {
      //         result.push({
      //             [key]: value,
      //             [key2]: value2,
      //             [key3]: formattedValue3
      //         });
      //     }
  
      //     return result;
      // }, []);

  // const columns = [
  //     { label: "Full Name", accessor: "full_name", sortable: true },
  //     { label: "Email", accessor: "email", sortable: false },
  //     { label: "Gender", accessor: "gender", sortable: true, sortbyOrder: "desc" },
  //     { label: "Age", accessor: "age", sortable: true },
  //     { label: "Start date", accessor: "start_date", sortable: true },
  //   ];
  // const demoData = [
  //   {
  //     "id": 1,
  //     "full_name": "Wendall Gripton",
  //     "email": "wg@creative.org",
  //     "gender": "Male",
  //     "age": 100,
  //     "start_date": "2022-01-26"
  //   },
  //   {
  //     "id": 2,
  //     "full_name": "samuel Mollitt",
  //     "email": "smol@github.io",
  //     "gender": "Male",
  //     "age": 60,
  //     "start_date": null
  //   },
  //   {
  //     "id": 3,
  //     "full_name": "Ole Rogge",
  //     "email": "orog@usatoday.com",
  //     "gender": "Male",
  //     "age": 50,
  //     "start_date": "2021-06-23"
  //   },
  //   {
  //     "id": 4,
  //     "full_name": "Elwin Huyge",
  //     "email": null,
  //     "gender": "Male",
  //     "age": 34,
  //     "start_date": "2021-06-07"
  //   },
  //   {
  //     "id": 5,
  //     "full_name": "Anthe Maybery",
  //     "email": "amay@dyndns.org",
  //     "gender": "Female",
  //     "age": 31,
  //     "start_date": "2021-07-05"
  //   },
  //   {
  //     "id": 6,
  //     "full_name": "Arny Amerighi",
  //     "email": "aameri@ibm.com",
  //     "gender": "Male",
  //     "age": 35,
  //     "start_date": "2021-03-09"
  //   },
  //   {
  //     "id": 7,
  //     "full_name": "Lynnell Shimmans",
  //     "email": "lshimmans6@addthis.com",
  //     "gender": "Male",
  //     "age": null,
  //     "start_date": "2021-06-03"
  //   },
  //   {
  //     "id": 8,
  //     "full_name": "Pierre Klug",
  //     "email": "pklug7@virginia.edu",
  //     "gender": "Female",
  //     "age": 36,
  //     "start_date": "2021-09-19"
  //   },
  //   {
  //     "id": 9,
  //     "full_name": "Melantha Jakeway",
  //     "email": "mjakeway8@noaa.gov",
  //     "gender": "Female",
  //     "age": 48,
  //     "start_date": "2021-01-07"
  //   },
  //   {
  //     "id": 10,
  //     "full_name": "Jodi Nickless",
  //     "email": "jnickless9@dagon.com",
  //     "gender": "Male",
  //     "age": 36,
  //     "start_date": "2021-02-05"
  //   }
  // ]


      // if (resultFormat === ResultFormating.Currency && columns.length > 0) {
    //     const lastColumn = columns[columns.length - 1];

    //     lastColumn.formatValue = (value: any) => {
    //         debugger;
    //         if (typeof value === 'number') {
    //             return value.toLocaleString('nb-NO', { style: 'currency', currency: 'NOK' });
    //         }
    //         console.log(value, "myvvalue");
    //         return value;
    //     };
    // }


          //}

      // const firstConnection = data.connectionResults.find(result => !result.isError); //TODO fix so that we get everything
      // if(firstConnection === undefined)
      //   return;
      // console.log(data.queryResult, "first connection");
      // const parsedData = JSON.parse(firstConnection.data)
      // const columns = generateColumnsFromData(parsedData, data.resultFormating);
      // console.log(parsedData, "parsedData");
      // const newOverlayContext: OverlayProps = {
      //   onClose: () => { },
      //   children:  <Table caption={"test"} columns={columns} data={parsedData} />
      // };
      
      // stateContext.setOverlayContext(newOverlayContext);