import React, { useEffect, useState, useCallback } from 'react'
import { Responsive, WidthProvider } from "react-grid-layout";
import { useStateContext } from '../../context/ContextProvider.tsx';
import { Messagebox, Loading, Parentcomponent, Customtooltip } from '../../components/index.js';

import Component, { ComponentType, UserComponent } from './../../models/Interfaces.tsx'

import './dashboard.css'
import { PresetIcons } from '../../util/constants.tsx';
import { mergeConnectionResults } from '../../util/commonfunctions.tsx';

const ResponsiveGridLayout = WidthProvider(Responsive)

interface DashboardProps {
  domElements: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
}



Dashboard.defaultProps = {
  className: "react-grid-layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => { },
  cols: { lg: 12, md: 12, sm: 9, xs: 6, xxs: 3 },
  breakpoints: { lg: 1400, md: 1100, sm: 840, xs: 575, xxs: 525 },
  containerPadding: [0, 0]
}

export default function Dashboard<DashboardProps>(props: DashboardProps) {
  const stateContext = useStateContext();
  const [layouts, setLayouts] = useState<{ [index: string]: any[] }>({});
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [mounted, setMounted] = useState(false);
  const [compactType, setCompactType] = useState<string | null>("vertical");
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: []
  });
  const [initialLayouts, setInitialLayouts] = useState<{ [index: string]: any[] }>({});

  const getComponentMeasurements = (compType: ComponentType) => {
    switch (compType) {
      case ComponentType.Small:
        return { w: 3, h: 3 }
      case ComponentType.Areachart:
        return { w: 9, h: 6 }
      case ComponentType.Piechart:
        return { w: 6, h: 6 }
      case ComponentType.Speedometer:
        return { w: 3, h: 6 }
      case ComponentType.Simpleranking:
        return { w: 3, h: 6 }
      case ComponentType.Linechart:
        return { w: 9, h: 9 }
      case ComponentType.Barcharthorizontal:
        return { w: 9, h: 9 }
      case ComponentType.Barchartvertical:
        return { w: 6, h: 12 }
      case ComponentType.Complexranking:
        return { w: 6, h: 6 }
      default:
        return { w: 3, h: 3 }
    }
  };

  const handleEditModeChange = () => {
    if (stateContext.editMode === false) {
      const currentLayout = layouts[currentBreakpoint] || layouts['lg'];

      const updatedComponents: UserComponent[] = [];
      if (currentLayout){
        for (let i = 0; i < currentLayout.length; i++) {
          const layoutComp = currentLayout[i];
          if (layoutComp) {
            const matchingComponent = stateContext.components.find(
              (comp) => comp.userComponentId.toString() === layoutComp.i.toString() //componentId
            );

            if (matchingComponent) {
              updatedComponents.push({
                userId: matchingComponent.userId,
                componentId: matchingComponent.componentId,
                position: `{"x":${layoutComp.x},"y":${layoutComp.y}}`,
                devicetype: matchingComponent.deviceType,
                // filter: myfilter,
              });
            }
          }
        }
        if (stateContext.components.length > 0 && mounted) {
          stateContext.updatePositions(updatedComponents);
        }
      }
    }
  }


  useEffect(() => {
    if (stateContext.components.length > 0 && mounted && stateContext.editMode == false) {
      handleEditModeChange();
    }
  }, [stateContext.editMode]); 

  useEffect(() => { 
    if (stateContext.isLoading || stateContext.isError) {
      setMounted(false);
    }
    if(!mounted && (!stateContext.isLoading && !stateContext.isError)){ //stateContext.components && stateContext.components.length > 0 &&
   
      setLayouts({
        lg: stateContext.components.map((component) => {
          
          let Yvalue = 0;
          let Xvalue = 0;
          if (component.position === "0") {
            const maxYComponent = stateContext.components
              .filter((comp) => comp.position !== "0")
              .reduce(
                (maxComponent, currentComponent) => {
                  const pos = JSON.parse(currentComponent.position);
                  const maxY = pos.y;

                  return maxY > maxComponent.maxY ? { currentComponent, maxY } : maxComponent;
                },
                { currentComponent: null as Component | null, maxY: -Infinity }
              );

            if (maxYComponent.currentComponent) {
              const maxYMeasurements = getComponentMeasurements(maxYComponent.currentComponent.componentType);
              maxYComponent.maxY = maxYComponent.maxY + maxYMeasurements.h;
              Xvalue = 0;
              Yvalue = maxYComponent.maxY;
              const positionJson = JSON.stringify({ x: Xvalue, y: Yvalue });
              stateContext.updateComponent(component.componentId, positionJson);
            }
          }
          else 
          {
            const position = JSON.parse(component.position);
            Xvalue = position.x;
            Yvalue = position.y;
          }
          const measurements = getComponentMeasurements(component.componentType);

          return {
            x: Xvalue,
            y: Yvalue,
            w: measurements.w,
            h: measurements.h,
            i: component.userComponentId.toString(),
          };
        })
      })

      setMounted(true);
    }
    //obsolete;
    if(mounted && (!stateContext.isLoading && !stateContext.isError))
    {
      //debugger;
    }
    //TODO error handling?
  }, [stateContext.isLoading, stateContext.isError]); 

  const handleLayoutChange = (layout: any, layouts: any) => {
    if(mounted){
      const updatedLayouts = { ...layouts };
      updatedLayouts[currentBreakpoint] = layout;
      setLayouts(updatedLayouts);
    }
  
  };
  // const OnLayoutChangeCallback = (layout, layouts) => {
  //   this.props.onLayoutChange(layout, layouts);
  //   debugger;
  // };
  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || []
    });
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);
  const handleComponentHover = (userComponentId) => {
    if (stateContext.editMode)
      return;
    if (hoveredItemId === userComponentId) {
      setHoveredItemId(null);
    }
    else {
      setHoveredItemId(userComponentId);
    }
  };

  const renderComponents = useCallback(() => {
    if (!mounted || stateContext?.components?.length < 1) {
      return <></>;
    }
    return layouts?.lg?.map((l) => {
      //console.log(l.i, "render l.i");
      const matchingComponent = stateContext.components.find((comp) => comp.userComponentId.toString() === l.i.toString()); //comp.userComponentId == l.i
      const isHovered = hoveredItemId === l.i && !stateContext.editMode;
      if(matchingComponent === undefined){
        return;
      }
      const componentData =  mergeConnectionResults(matchingComponent.connectionResults, stateContext.filter); 
      
      matchingComponent.queryResult = componentData == undefined ? "{}" : JSON.stringify(componentData);

      return (
        <div
          key={l.i}
          className="grid-item"
          //className={`grid-item ${isClicked ? 'clicked' : ''}`}
          onMouseEnter={() => handleComponentHover(l.i)}
          onMouseLeave={() => handleComponentHover(null)}
        >
          {matchingComponent && (
            <>
              <Parentcomponent
                data={matchingComponent}
                closeCallback={closeComponentCallback}
              />
              {isHovered && (
                <Customtooltip text={matchingComponent.explanation} />
              )}
            </>
          )}
        </div>
      );
    });
  }, [stateContext.editMode, layouts, stateContext.components, hoveredItemId]);  // stateContext.editMode,  hoveredItemId,

  const closeComponentCallback = useCallback((id: number) => {
    console.log(id, "id in closeComponentCallback");

    const layoutKey = Object.keys(layouts).find(key => layouts[key].some(item => item.i === id.toString()));
    //debugger; //TODO working on it;
    if (layoutKey) {
      const updatedLayouts = {
        ...layouts,
        [layoutKey]: layouts[layoutKey].filter(item => item.i !== id.toString())
      };
      setLayouts(updatedLayouts);
    }
    stateContext.removeComponent(id);
  }, [layouts]);

  return (
    <>
      {stateContext.isLoading && <Loading />}
      {!stateContext.isLoading && stateContext.isError && (
        <Messagebox
          message={stateContext.errorMessage.toString()}
          title="Ingen kontakt med APIet"
          icon={PresetIcons['FaTimesCircle']}
        />
      )}
      {!stateContext.isLoading && !stateContext.isError && (
        <div className="dashboard-container"  >
          <div
            className="component-container">
            <ResponsiveGridLayout
              {...props}
              layouts={layouts}
              measureBeforeMount={false}
              useCSSTransforms={false}
              preventCollision={!compactType}
              allowOverlap={false}
              onLayoutChange={handleLayoutChange}
              onBreakpointChange={onBreakpointChange}
              isResizable={false}
              isDraggable={stateContext.editMode}
              isBounded={true}
              margin={[20, 20]}
              draggableHandle=".draggable-container"
            // handle=".grid-item"
            >
              {renderComponents()}
            </ResponsiveGridLayout>
          </div>
        </div>
      )}
    </>
  );
}





   // setLayouts({
      //   lg: stateContext.components.map((component) => {
      //     const position = JSON.parse(component.position);
      //     const measurements = getComponentMeasurements(component.componentType);
      //     return {
      //       x: position.x,
      //       y: position.y,
      //       w: measurements.w,
      //       h: measurements.h,
      //       i: component.userComponentId.toString(),
      //     };
      //   }),
      // });

  // useEffect(() => {
  //   if (mounted && !layouts) { //stateContext.components.length > 0 && !mounted
  //     setInitialLayouts({
  //       lg: stateContext.components.map((component) => {
  //         const position = JSON.parse(component.position);
  //         const measurements = getComponentMeasurements(component.componentType);
  //         return {
  //           x: position.x,
  //           y: position.y,
  //           w: measurements.w,
  //           h: measurements.h,
  //           i: component.userComponentId.toString(),
  //           //i: component.componentId.toString(),
  //         };
  //       }),
  //     });
  //   }
  //   if (mounted && layouts) { //stateContext.components.length > 0 && 
  //     setLayouts({
  //       lg: stateContext.components.map((component) => {
  //         let Yvalue = 0;
  //         let Xvalue = 0;
  //         if (component.position === "0") {
  //           const maxYComponent = stateContext.components
  //             .filter((comp) => comp.position !== "0")
  //             .reduce(
  //               (maxComponent, currentComponent) => {
  //                 const pos = JSON.parse(currentComponent.position);
  //                 const maxY = pos.y;

  //                 return maxY > maxComponent.maxY ? { currentComponent, maxY } : maxComponent;
  //               },
  //               { currentComponent: null as Component | null, maxY: -Infinity }
  //             );

  //           if (maxYComponent.currentComponent) {
  //             const maxYMeasurements = getComponentMeasurements(maxYComponent.currentComponent.componentType);
  //             maxYComponent.maxY = maxYComponent.maxY + maxYMeasurements.h;

  //             Xvalue = 0;
  //             Yvalue = maxYComponent.maxY;


  //             const positionJson = JSON.stringify({ x: Xvalue, y: Yvalue });
  //             stateContext.updateComponent(component.componentId, positionJson);
  //           }
  //         }
  //         else 
  //         {
  //           const position = JSON.parse(component.position);
  //           Xvalue = position.x;
  //           Yvalue = position.y;
  //         }
  //         const measurements = getComponentMeasurements(component.componentType);

  //         return {
  //           x: Xvalue,
  //           y: Yvalue,
  //           w: measurements.w,
  //           h: measurements.h,
  //           i: component.userComponentId.toString(),
  //         };
  //       })
  //     })

  //   }
  // }, [mounted]); 

//     if(component.position === "0"){
//   console.log("here");
//   const components = stateContext.components.filter(comp => comp.position !== "0");

//   const componentWithMaxY = components.reduce<{ currentComponent: Component | null, maxY: number }>((maxComponent, currentComponent) => {
//     const position = JSON.parse(currentComponent.position);
//     const maxY = position.y;

//     if (maxY > maxComponent.maxY) {
//       return { currentComponent, maxY };
//     }

//     return maxComponent;
//   }, { currentComponent:null, maxY: -Infinity });
//   if (componentWithMaxY.currentComponent) {
//     const maxYMeasurements = getComponentMeasurements(componentWithMaxY.currentComponent.componentType);
//     const measurements = getComponentMeasurements(component.componentType);
//     return {
//       x: 0,
//       y: componentWithMaxY.maxY + maxYMeasurements.h,
//       w: measurements.w,
//       h: measurements.h,
//       i: component.componentId.toString(),
//     };
//   }
//   return; //TODO ???
// }