import { useState } from "react";
import {encryptWithSalt, decryptWithSalt} from './commonfunctions.tsx';
export function useToken() {
  const salt = 'k3fx5wa';
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : null;

  };

  const [token, setToken] = useState(getToken());

  const saveToken = (username, password) => {
    const expirationTime = new Date().getTime() + (24 * 60 * 60 * 1000);
    const encryptedPassword = encryptWithSalt(password, salt);
    const userToken = {
      expirationTime,
      username,
      password: encryptedPassword
    };
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
    console.log(userToken, "saveToken");
  };

 

  return {
    setToken: saveToken,
    token,
    encryptWithSalt,
    decryptWithSalt
  };
  

  
}

