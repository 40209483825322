import React from 'react';
import {Header, Actioncontainer, Dashboard, Footer} from './../containers/index.js'

const Dashboardpage = () => {
  return (
    <div className="page-container">
      <Header />
      <Actioncontainer />
      <Dashboard />
      <Footer />
    </div>
  );
};

export default Dashboardpage;