import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import { ContextProvider } from './context/ContextProvider.tsx';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <StrictMode>
  <ContextProvider>

      <App />

  </ContextProvider>
  </StrictMode> 
);


{/* <React.StrictMode>
<App />
</React.StrictMode> */}
