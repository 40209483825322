import React, {useState} from 'react'

import { useStateContext } from '../../context/ContextProvider.tsx';

import './customtooltip.css';

export default function Customtooltip({text}){

  const stateContext = useStateContext();
  if(stateContext.editMode === true)
    return null;

    return(
      <div className="tooltip"> 
        {text}
      </div> 
  );
}