import React from 'react'
import { IconType } from 'react-icons';
import { MessageboxProps } from '../../models/Interfaces.tsx';

import "./messagebox.css"

export default function Messagebox(props:MessageboxProps){
  const {title, message, icon} = props
  
  return (
    <div className="basic-container">
      <div className="message-container">
        <div className="top-message-container">
        {title}
        </div>
        <div className="display-message-container">
        {message}
        </div>
      </div>
      {icon && (
      <div className="icon-container">
        {icon} {/* TODO size={48} color="red"} */}
      </div>
    )}
    </div>
  )
}
