
import React, { useState, useEffect } from 'react';
import "./loading.css";


export default function Loading() {
    const [loadingText, setLoadingText] = useState('Laster...');
  
    useEffect(() => {
      const timeouts = [
        { text: 'Hold the line!', duration: 5000 }, // 5 seconds
        { text: 'Tar litt lengre tid en ventet...', duration: 10000 }, // 10 seconds
        { text: 'Vekker Hamsterne...', duration: 15000 }, // 15 seconds
        { text: 'Sql-serverne våkner fra sine dype dvaler...', duration: 20000 }, // 20 seconds
        { text: 'Nesten der...', duration: 30000 } // 30 seconds
      ];
  
      timeouts.forEach(({ text, duration }) => {
        const timeoutId = setTimeout(() => {
          setLoadingText(text);
        }, duration);
  
        return () => clearTimeout(timeoutId);
      });
    }, []);
  
    return (
      <div className="loading-container">
        <h3>{loadingText}</h3>
        <div className="spinner-container">
          <div className="loading-spinner" />
        </div>
      </div>
    );
  };
