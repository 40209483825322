import React, { Fragment } from 'react'

import "./overlay.css";
import { useStateContext } from '../../context/ContextProvider.tsx';
import { OverlayProps } from '../../models/Types.tsx';
import { Componentgroups, Componentlist, Connectionslist } from '../../components/index.js';



export default function Overlay(props: OverlayProps) {
  const { onClose, children } = props // onClose, onCloseCallback,

  const stateContext = useStateContext();

  const isComponentsList = React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.type === Componentlist
  );
  const isConnectionsList = React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.type === Connectionslist
  );
  const myOnClose = (e) => {

    const className = e.target.className;
    if (className === "overlay_close" || className === "overlay_background") {
      stateContext.setShowOverlay(false);
      stateContext.setOverlayContext(null);
      if(isComponentsList)
      {
        onClose(stateContext.selectedComponents);
      }
      else if(isConnectionsList)
      {
        onClose(stateContext.filter);
      }
      else
      {  
        onClose('');
      }
    }
  }

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      {stateContext.showOverlay && (
        <div className="overlay">
          <div className="overlay_background" onClick={myOnClose}>
            <div className="overlay_container" onClick={stopPropagation}>
              <div className="overlay_controls">
                <button
                  className="overlay_close"
                  type="button"
                  onClick={(e) => {
                    myOnClose(e)
                  }}
                />
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}