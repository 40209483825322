import React, { createContext, useState, useEffect, useContext } from 'react'
import Component, { ContextValue, User, UserComponent, ConnectionResults } from '../models/Interfaces';
import { getData, removeUserComponent, updateUserComponents, updateUserComponent, updateUserCompPrio, updateUserSettings, getAllConnections, getAllComponents } from '../services/api.tsx';
import { SelectedComponents, OverlayProps } from '../models/Types.tsx';
import { CredentialsModel } from '../models/Dtos.tsx';


const StateContext = createContext<ContextValue | null>(null);

type Props = {
  children: React.ReactNode;
}

// const cred: CredentialsModel = { //TODO this entire consept needs to be moved outside I think.
//   // username: "lars.kristian.andersen@pck.no",
//   // password: "252S4M"
// };

export const ContextProvider = (children:Props) => { 
  const [credentials, setCredentials] = useState<CredentialsModel | null>(null);
  // const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<User>({} as User);
  const [components, setComponents] = useState<Component[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [timestamp, setTimestamp] = useState(new Date());
  const [editMode, setEditMode] = useState(false);
  const [darkThemeEnabled, setDarkThemeEnabled] = useState<boolean>(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;
  });
  const [connections, setConnections] = useState<ConnectionResults[]>([]);
  const [filter, setFilter] = useState<string[]>([]); //icensenumbers string[]. 
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [overlayContext, setOverlayContext] = useState<OverlayProps | null>(null);
  const [dragging, setDragging] = useState(false);

  const[selectedComponents, setSelectedComponents] = useState<SelectedComponents[]>([])

  const updateUser = async(updatedUser:User): Promise<void> => {
    try{
      
      if(updatedUser.username.length < 1)
        throw new Error("Ingen bruker ble hentet ved førte payload");

      const result = await updateUserSettings(updatedUser);
    }
    catch{

    }
  }
  const addComponents = async (newComponents: Component[], position:string): Promise<void> => {
    setIsLoading(true);
    try {
      const myfilter = filter.length > 0 ? filter.join(', ') : '';
      const userComponents:UserComponent[] = newComponents.map((component) => {
        return {
          userId: user.userId,
          componentId: component.componentId,
          position:position,
          devicetype:0, //TODO
          filter:myfilter
        }
      });
      const updatedComponents: Component[] = await updateUserComponents(userComponents, user.username);
      setComponents((prevComponents) => [...prevComponents, ...updatedComponents]);
      console.log(newComponents);
    } catch (error) {
      console.log(error);
      setErrorMessage(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const removeComponent = async (removeId: number): Promise<void> => {
    try 
    {
      const result = await removeUserComponent(removeId);
      if(result)
      setComponents((prevComponents) =>
        prevComponents.filter((component) => component.userComponentId !== removeId)
      );
      console.log(result, "remove components");
    } 
    catch (error) 
    {
      console.log(error);
    }
  }
  const updateComponentFilters = async (componentsToFilter:UserComponent[], filter:string[]): Promise<void>  =>  {

  }
  const filterComponents = (licenseNoFilter: string[]) => {
    try {
      if(components.length === 0)
        return;
      // Clone the components array to avoid mutating the original state
      const updatedComponents: Component[] = components.map((component) => {
        // Reset the queryResult for each component
        let filteredResults: string[] = [];
        
        if (licenseNoFilter.length === 0) {
          filteredResults = component.connectionResults
            .filter((result) => !result.isError)
            .map((result) => result.data);
        } else {
          filteredResults = component.connectionResults
            .filter((result) => !result.isError && licenseNoFilter.includes(result.licenseNo))
            .map((result) => result.data);
        }
  
        // Parse each string element into an array of objects
        const parsedResults = filteredResults.map((result) => JSON.parse(result));
  
        // Concatenate the arrays together
        const concatenatedResults = parsedResults.reduce((acc, val) => acc.concat(val), []);
  
        // Convert the concatenated array to a JSON string
        const mergedData = JSON.stringify(concatenatedResults);
  
        // Update the component's queryResult
        return {
          ...component,
          queryResult: mergedData,
        };
      });
  
      // Set the updated components state
      setComponents(updatedComponents);
  
      console.log('Components updated with filtered data');
    } catch (error) {
      console.log('Error filtering components:', error);
    }
  };
  // const filterComponents = (licenseNoFilter:string[]) => {
  //   try
  //   {
  //     let filteredComponents: Component[] = [...components];

  //     let filteredData: string[] = [];
  //     if (licenseNoFilter.length === 0) {
  //       filteredData = filteredComponents.flatMap((component) =>
  //         component.connectionResults
  //           .filter((result) => !result.isError)
  //           .map((result) => result.data)
  //       );
  //     } 
  //     else {
  //       filteredData = filteredComponents.flatMap((component) =>
  //         component.connectionResults
  //           .filter((result) => !result.isError && licenseNoFilter.includes(result.licenseNo))
  //           .map((result) => result.data)
  //       );
  //     }
  //     const mergedData: string = filteredData.join(' ');
  //     debugger;

  //     const updatedComponents: Component[] = filteredComponents.map((component) => ({
  //       ...component,
  //       queryResult: JSON.stringify(mergedData),
  //     }));
  
  //     setComponents(updatedComponents);

  //     console.log(mergedData);

  //   }
  //   catch(error)
  //   {
  //     console.log("filterComponents errors");
  //   }
  // }
  const getConnections = async (): Promise<ConnectionResults[]> => {
    try{
      if(credentials === null){
        throw new Error("OBS! Fant ingen rettigheter");
      }
      const ConnectionResults = await getAllConnections(credentials);
      console.log("connectionRes", ConnectionResults);
      return ConnectionResults;
    } 
    catch (error)
    {
      console.log(error);
      throw error;
    } 
  }
  const getMySelectedComponents = () => {
    return selectedComponents;
  }
  const updateComponents = async (newSelectedComponents: SelectedComponents[]): Promise<void> => { //
   
    setIsLoading(true);
    try {
      // if(newSelectedComponents.length < 1)
      //   return;
      const myfilter = filter.length > 0 ? filter.join(', ') : '';
      const userComponents: UserComponent[] = newSelectedComponents
        .filter((component) => component.checkstate)
        .map((component) => ({
          userId: user.userId,
          componentId: component.id,
          position: "0",
          devicetype: 0, // TODO
          filter:myfilter
      }));
      //console.log(userComponents, "updateComponent userComponents");
      const result = await updateUserComponents(userComponents, user.username);
      console.log(result, "result userComponents");
      if(result === ""){
        console.log("updateUserComponents seems to have an empty result. No refresh available");
        return;
      }
      const refreshedComponents: Component[] = result; //NB! result is components;
      setComponents(refreshedComponents); 

      console.log(refreshedComponents);
    } catch (error) {
      console.log(error);
      setErrorMessage(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const updateComponent = async(componentId:number, position:string): Promise<void> => {
    const updatedUserComponent:UserComponent = {
      userId: user.userId,
      componentId: componentId,
      position: position,
      devicetype: 0
    }
    console.log(updatedUserComponent, "update singular user component");
    try
    {
      const result = await updateUserComponent(updatedUserComponent);
      //TODO?
    }
    catch(error){
      console.log(error, "update user component");
    }
  }
  const updatePositions = async (updatedComponents:UserComponent[]): Promise<void> => {
    console.log(updatedComponents, "updatePositions call");
    try
    {
      const result = await updateUserCompPrio(updatedComponents);
      //TODO?
    }
    catch(error){
      console.log(error, "update user component");
    }
    
  }
  const updateSelectedComponents = (selcomp:SelectedComponents[]) => 
  {
    console.log(selectedComponents, "updatesSelectedComponents");
    if(selcomp?.length < 1)
      return;
    setSelectedComponents(selcomp);
  }

  async function fetchDataFromApi() {

    setIsLoading(true);

    try {
      if(credentials === null){
        throw new Error("OBS! Fant ingen rettigheter");
      }
    console.log(credentials, "getData Credentials");
      // debugger;
      const data = await getData(credentials);

        console.log(data, "data");
        const { user: fetchedUser, components: fetchedComponents, connections:fetchedConnections } = data;
        setUser(fetchedUser);
        const filter = fetchedUser.connectionsFilter.length > 0 ? fetchedUser.connectionsFilter.split(',') : []
        setFilter(filter);

        setComponents(fetchedComponents);
        console.log(fetchedComponents, "fetchComponents");
        setDarkThemeEnabled(fetchedUser.darkTheme !== undefined ? fetchedUser.darkTheme : false);
        setTimestamp(new Date());
        setConnections(fetchedConnections);
       
        console.log(fetchedConnections, "fectchConnections");
    } 
    catch (error)
    {
      console.error('Error fetching data from api:', error);
      setErrorMessage(error);
      setIsError(true);

    }
    finally{
      setIsLoading(false);
      
    }
  }
  useEffect(() => {
    if(credentials === null)
      return;
    fetchDataFromApi();

  }, [credentials]);

  

  const contextValues: ContextValue = {
    credentials,
    setCredentials,
    user,
    setUser,
    components,
    setComponents,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    errorMessage,
    setErrorMessage,
    darkThemeEnabled,
    setDarkThemeEnabled,
    timestamp,
    setTimestamp,
    editMode,
    setEditMode,
    showOverlay,
    setShowOverlay,
    overlayContext,
    setOverlayContext,
    dragging,
    setDragging,
    fetchDataFromApi,
    addComponents,
    removeComponent,
    updateSelectedComponents,
    updateComponents,
    getMySelectedComponents,
    updatePositions,
    updateComponent,
    selectedComponents,
    setSelectedComponents,
    updateUser,
    getConnections,
    updateComponentFilters,
    connections,
    setConnections,
    filterComponents,
    filter,
    setFilter,
  };

  return (
    <StateContext.Provider value={contextValues}>
      {children.children}
    </StateContext.Provider>
  )
}

export const useStateContext = (): ContextValue => {
  const context = useContext(StateContext);
  if (!context) 
    throw new Error('useStateContext must be used within a ContextProvider');
  return context;
};



  // const updateComponents = async (updatedComponents: Component[]): Promise<void> => {
  //   setIsLoading(true);
  //   try {
  //     const userComponents: UserComponent[] = updatedComponents.map((component) => {
  //       return {
  //         userId: user.userId,
  //         componentId: component.componentId,
  //         position:"0",
  //         devicetype:0 //TODO
  //       }
  //     });

  //     const result = await updateUserComponents(userComponents);
  //     if(result === "")
  //      return;
  //     const refreshedComponents: Component[] = result;
  //     setComponents(refreshedComponents); 
  //     console.log(refreshedComponents);
  //   } catch (error) {
  //     console.log(error);
  //     setIsError(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };