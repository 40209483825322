import React from 'react'
import './errorcomponent.css'
import { ErrorProps } from '../../models/Types'



export default function Errorcomponent(props: ErrorProps) { //
  const {message, component} = props
  return (
    <div className="base-container  error-container ">
      <div className="title-container">
      {component.title}
      </div>
      <h4> {message}</h4>
    </div>
  )
}