import React, {useState, useEffect} from 'react'
import Logo from '../../assets/Logo.png'
import "./header.css"
import { useStateContext } from '../../context/ContextProvider.tsx'

export default function Header() {
  const stateContext = useStateContext();
  const [lastFetched, setLastFetched] = useState(new Date());

  useEffect(() => {
    // Update lastFetched whenever stateContext.timestamp changes
    setLastFetched(new Date(stateContext.timestamp));
  }, [stateContext.timestamp]);

  return (
    <div className="header-container">
      <img src={Logo} alt="Logo" />
      <p>Sist hentet: {lastFetched.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}</p>
    </div>
  );
}