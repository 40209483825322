import React, { useEffect, useState } from 'react'


import Component, { ResultFormatting, SimpleModel } from '../../models/Interfaces.tsx';
import { FormatResultValue, ParseSimpleQueryResult, generateColumnsFromData, groupAndSumSimpleData, groupTwoColumnData, mergeConnectionResults, sortByX, sortDataByFirstColumn } from "../../util/commonfunctions.tsx";
import {AreaChart, ResponsiveContainer, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import {Errorcomponent} from '../'
import "./areachart.css"
import { useStateContext } from '../../context/ContextProvider.tsx';



export default function Areachart(props:Component){ //{data}: {data:ComponentDTO}
  const {title, queryResult, connectionResults, resultFormatting} = props;
  const [componentData, setComponentData] = useState<string[]>([]);
  const stateContext = useStateContext();
  const [parsingError, setParsingError] = useState('');
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    setMounted(false);
    const result = JSON.parse(queryResult);
    if (result instanceof Error) {
      setParsingError("Error: kunne ikke parse resultatet");
      
    } 
    else 
    {
      const mergedData:string[] = mergeConnectionResults(connectionResults, stateContext.filter);
      if(mergedData.length === 0)
        return;
      const data = groupTwoColumnData(mergedData);
      const parsedData = data.map(item => {
        const keys = Object.keys(item);
        const lastKey = keys[keys.length - 1]; // Get the last key dynamically
        return {
            ...item,
            [lastKey]: parseFloat(item[lastKey])
        };
    });

      const sortedData = sortDataByFirstColumn(parsedData);
      setComponentData(sortedData);
    }
    setMounted(true);
  }, [stateContext.filter]);

  const datakeyX = componentData.length > 0 ? Object.keys(componentData[0])[0] : "Mnd";
  const datakeyY = componentData.length > 0 ? Object.keys(componentData[0])[1] : "Beløp";


  const CustomTooltip = ({ active, payload, label }) => {

    const compact = payload && payload.some(entry => entry.value > 100000);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{`${datakeyX}: ${label}`}</p>
          <ul>
             {payload.map((entry, index) => {
              const displayValue = FormatResultValue(entry.value, resultFormatting, compact);

            return (
              <li key={index} style={{ color: entry.fill }}>
                {`${entry.dataKey}: ${displayValue}`}
              </li>
            );
          })}
          </ul>
        </div>
      );
    }
    return null;
  };
    // let displayValue;
  // if(resultFormatting === ResultFormatting.Currency){
  //   displayValue = new Intl.NumberFormat("en-US", {
  //     notation: "compact",
  //     compactDisplay: "short",
  //   }).format(entry.value);
  // }
  // else
  // {
  //   displayValue = entry.value;
  // }
  return(
    <div className="base-container">
      <div className="title-container">
      {title}
      </div>
      {parsingError ? <Errorcomponent
      component={props}
      message="Error: kunne ikke parse resultatet"
      />
      : mounted &&
      <div className="areachart-container">
      <ResponsiveContainer width="100%" height={220} >
        <AreaChart
          data={componentData}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient  id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#129a74" stopOpacity={0.6}/>
              <stop offset="95%" stopColor="#00C49F" stopOpacity={0.2}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray={"3 3"} />
          <XAxis dataKey={datakeyX} /> {/*"Variant" */}
          <YAxis
            // domain={[0, (maxValue)]}
            tickFormatter={(value) =>
              new Intl.NumberFormat("nb-NO", {
                notation: "compact",
                compactDisplay: "short",
              }).format(value)
            }
          />
          <Tooltip cursor={{ fill: 'var(--text-secondary)' }} content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Area type="monotone" dataKey={datakeyY} stroke="#00C49F" fill="url(#colorUv)" />
        </AreaChart>
        </ResponsiveContainer>
      </div> 
        }
    </div>
  )
}


  // const columns = generateColumnsFromData(result, props.resultFormating, false);
  // const lastObject = componentData[componentData.length - 1];
  // const keys = Object.keys(lastObject);
  // const lastColumnKey = keys[keys.length - 1];
  // const comparerModels: SimpleModel[] = result;
  // const data = groupAndSumSimpleData(comparerModels).slice(0,5);

  // let maxValue = 100000;
  // maxValue = mergedData.reduce((max, current) => {

  //   const currentValue = parseFloat(current[1]);

  //   // Compare with the previous maximum value
  //   return currentValue > max ? currentValue : max;
  // }, Number.NEGATIVE_INFINITY);


  




  // const result = ParseSimpleQueryResult(queryResult);
  // if (result instanceof Error) {
  //   return <Errorcomponent
  //   component={props}
  //   message="Error: kunne ikke parse resultatet"
  //   />
  // }
  // const result = JSON.parse(queryResult);
  // //const result = ParseSimpleQueryResult(queryResult);
  // if (result instanceof Error) {
  //   return <Errorcomponent
  //     component={props}
  //     message="Error: kunne ikke parse resultatet"
  //   />
  // }
  // const comparerModels: SimpleModel[] = result;

  // const data = groupAndSumSimpleData(comparerModels);
  // const sortedData = sortByX(data);

  // useEffect(() =>
  // {
    
  // }, [stateContext.filter]);
  // const componentData = groupTwoColumnData(result);
;
  // // sortByX(componentData);
