import React, { useState, useEffect } from 'react'

import { useStateContext } from '../../context/ContextProvider.tsx'
import { Loading, Messagebox } from '../'
import Component, { ComponentGrouping } from '../../models/Interfaces.tsx'
import "./componentlist.css";
import { getAllComponents } from '../../services/api.tsx';
import { SelectedComponents } from '../../models/Types.tsx';




export default function Componentlist({ title }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const stateContext = useStateContext();


  useEffect(() => {
    if (stateContext.selectedComponents.length > 0) {
      // console.log(stateContext.selectedComponents, "selected Components returning becuase greater than 0");
      stateContext.updateSelectedComponents([]);
    }

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const allComponents: Component[] = await getAllComponents();
        const initialSelectedComponents: SelectedComponents[] = allComponents.map((component) => ({
          id: component.componentId,
          text: component.title,
          checkstate: false,
          componentGroup: component.componentGrouping
        }));
        const updatedSelectedComponents: SelectedComponents[] = initialSelectedComponents.map((component) => {
          const existsInContext = stateContext.components.find((c) => c.componentId === component.id);

          if (existsInContext) {
            return {
              // id:existsInContext.componentId,
              // text:existsInContext.text,
              ...component,
              checkstate: true
            };
          }
          return component;
        })

        stateContext.updateSelectedComponents(updatedSelectedComponents);
      } catch (error) {
        console.log(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();

  }, [setIsError, setIsLoading]); //stateContext.components, stateContext.selectedComponents, 

  // if(numberProp !== 1)
  // return <></>

  // const groupComponents = (components: SelectedComponents[]): Map<ComponentGrouping, SelectedComponents[]> => {
  //   const groupedComponents = new Map<ComponentGrouping, SelectedComponents[]>();

  //   components.forEach((component) => {
  //     if (!groupedComponents.has(component.componentGroup)) {
  //       groupedComponents.set(component.componentGroup, []);
  //     }
  //     groupedComponents.get(component.componentGroup)?.push(component);
  //   });

  //   return groupedComponents;
  // };
  
  const getStandardComponents = (components: SelectedComponents[]): SelectedComponents[] => {
    return components.filter((component) => component.componentGroup === ComponentGrouping.Standard);
  };
  
  const groupComponents = (components: SelectedComponents[]): Map<ComponentGrouping, SelectedComponents[]> => {
    const groupedComponents = new Map<ComponentGrouping, SelectedComponents[]>();
  
    components.forEach((component) => {
      if (component.componentGroup !== ComponentGrouping.Standard) {
        if (!groupedComponents.has(component.componentGroup)) {
          groupedComponents.set(component.componentGroup, []);
        }
        groupedComponents.get(component.componentGroup)?.push(component);
      }
    });
  
    return groupedComponents;
  };

  const handleOnChange = (clickedComponent: SelectedComponents) => {
    const updatedSelectedComponents = stateContext.selectedComponents.map((component) => {
      if (component.id === clickedComponent.id) {
        return {
          ...component,
          checkstate: !component.checkstate,
        };
      }
      return component;
    });
    stateContext.updateSelectedComponents(updatedSelectedComponents);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && isError && (
        <Messagebox
          message="Det har oppstått en feil, prøv igjen. Om problemet vedvarer ta kontakt med din forhandler."
          title="Kunne ikke hente statistikkene"
        />
      )}
      {!isLoading && !isError && (
        <>
          <div className="title-container">
            {title}
          </div>
          
          <div className="componentlist_container">
            <div key="standard">
              <fieldset>
                <legend>{ComponentGrouping[ComponentGrouping.Standard]}</legend>
                <ul className="checkbox-container">
                  {getStandardComponents(stateContext.selectedComponents).map((component) => (
                    <li key={component.id} className="list-item">
                      <label className="componentlist-label">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${component.id}`}
                          name={component.text}
                          value={component.id}
                          checked={component.checkstate}
                          onChange={() => handleOnChange(component)}
                        />
                        {component.text}
                      </label>
                    </li>
                  ))}
                </ul>
              </fieldset>
              
            </div>
            {stateContext.user.isActive && 
                <div className="text-between">For kun 100,- i måneden kan du få tilgang til all statistikken nedenfor!</div>
            }
            {Array.from(groupComponents(stateContext.selectedComponents)).map(([group, components]) => (
              <div key={group}>
                <fieldset>
                  <legend>{ComponentGrouping[group]}</legend>
                  <ul className="checkbox-container">
                    {components.map((component) => (
                      <li key={component.id} className="list-item">
                        <label className="componentlist-label">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${component.id}`}
                            name={component.text}
                            value={component.id}
                            checked={component.checkstate}
                            onChange={() => handleOnChange(component)}
                            disabled={!stateContext.user || !stateContext.user.isActive}
                          />
                          {component.text}
                        </label>
                      </li>
                    ))}
                  </ul>
                </fieldset>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

// return (
//   <>
//     {isLoading && <Loading />}
//     {!isLoading && isError && (
//       <Messagebox
//         message="Det har oppstått en feil, prøv igjen. Om problemet vedvarer ta kontakt med din forhandler."
//         title="Kunne ikke hente statistikkene"
//       />
//     )}
//     {!isLoading && !isError && (
//       <div className="componentlist_container">
        
//         <ul className="checkbox-container">
//           {stateContext.selectedComponents.map((component, index) => {
//             return (
//               <li key={index} className="list-item">
//                 <label className="componentlist-label">
//                   <input
//                     type="checkbox"
//                     id={`custom-checkbox-${component.id}`}
//                     name={component.text}
//                     value={component.id}
//                     checked={component.checkstate}
//                     onChange={() => handleOnChange(component)}
//                   />
//                   {component.text}
//                 </label>
//               </li>
//             );
//           })}
//         </ul>
//       </div>
//     )}
//   </>
// )