import React, { useEffect, useState} from "react";
import Component, {ComplexModel} from "../../../models/Interfaces.tsx";
import { ParseComplexQueryResult, complexGroupByVarietyAndSum, generateColumnsFromData, groupThreeColumnData, mergeConnectionResults, sortDataByLastColumn } from "../../../util/commonfunctions.tsx";
import { PresetIcons } from "../../../util/constants.tsx";
import {Errorcomponent, Table} from "../../index.js"

import "./complexranking.css"
import { useStateContext } from "../../../context/ContextProvider.tsx";

export default function Complexranking(props:Component){
  const {title, queryResult, connectionResults} = props

  const stateContext = useStateContext();

  const [componentData, setComponentData] = useState<string[]>([]);
  const [parsingError, setParsingError] = useState('');
  const [mounted, setMounted] = useState(false);

  const result = JSON.parse(queryResult);

  useEffect(() => {
    setMounted(false);
    // const result = JSON.parse(queryResult);
    if (result instanceof Error) {
      setParsingError("Error: kunne ikke parse resultatet");
    } 
    else 
    {
      const mergedData:string[] = mergeConnectionResults(connectionResults, stateContext.filter);
      
      if(mergedData.length < 1)
        return;
      const data = groupThreeColumnData(mergedData, 'asc');
      const sortedData = sortDataByLastColumn(data).slice(0,5); 

      setComponentData(sortedData);
    }
    setMounted(true);
  }, [stateContext.filter]);

  const columns = generateColumnsFromData(result, props.resultFormatting, false);


  const lastObject = result[result.length - 1];
  const keys = Object.keys(lastObject);
  const lastColumnKey = keys[keys.length - 1];


  return (
    <div className="base-container complex-ranking-container">
      <div className="title-container ranking_title-container">
        {title}
      </div>
      {parsingError ? (
        <Errorcomponent
          component={props}
          message="Error: kunne ikke parse resultatet"
        />
      ) : (
        <>
          {mounted && componentData.length > 0 ? (
            <Table
              caption={''}
              columns={columns}
              data={componentData}
              defaultSortField={lastColumnKey}
              defaultSortOrder={'asc'}
            />
          ) : (
            <p>Ingen data tilgjengelig</p>
          )}
        </>
      )}
    </div>
  );
  
}
{/* <div className="complex_ranking-table">
        <div className="complex_table-row complex_table-header">
          <div className="complex_table-cell">Variant</div>
          <div className="complex_table-cell">Count</div>
          <div className="complex_table-cell">DG</div>
        </div>
        {data.map((item, index) => (
          <div className="complex_table-row" key={index}>
            <div className="complex_table-cell">{item.Serie}</div>
            <div className="complex_table-cell">{item.Xvalue}</div>
            <div className="complex_table-cell">{item.Yvalue}</div>
          </div>
        ))}
      </div> */}